import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { useNavigate, useLocation } from "react-router-dom";
import { APIURL } from '../Global';

function OTPComponent() {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const token = location.state?.token || localStorage.getItem('user')?.token;
  const username = location.state?.username ;

  // console.log(location.state?.token);
  // console.log(localStorage);
  


  const otpInputRef = useRef(null);

  useEffect(() => {
    if (otpInputRef.current) {
      otpInputRef.current.focus();
    }
  }, []);

  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(""); // Reset error state
    const response = await fetch(`${APIURL}/backend/api/verify-otp/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${token}`
      },
      body: JSON.stringify({ otp, username })
    });

    if (response.ok) {
      const responseJson = await response.json();
      const data = responseJson.data;
      console.log(responseJson);
      if(responseJson.status == "success"){
        if(data.user_status == 1){
          // localStorage.clear();
          localStorage.setItem('codeDcUserToken', data.token);
          localStorage.setItem('codeDcUsername', data.username);
          localStorage.setItem('codeDcUserEmail', data.email); 
          localStorage.setItem('codeDcUserMobile', data.mobile);
          localStorage.setItem('codeDcUserId', data.user_id);
          localStorage.setItem('codeDcUserRole', data.role);
          localStorage.setItem('codeDcUserLevel', data.level_name);
          localStorage.setItem('codeDcUserGroupType', data.group_type);
          navigate('/dashboard');
          // navigate('/seconddashboard');        
        }else{              
          setError("User is Inactive");
        }

      }else if(responseJson.status == "error"){               
        setError(responseJson.message || "OTP validation failed");
      }else{
        setError(responseJson.message || "OTP validation failed");
      }
     
      // OTP validated successfully, navigate to the dashboard or another page
    } else {
      const errorData = await response.json();
      console.log("t4");
      setError(errorData.message || "OTP validation failed");
    }
  };

return (
  <>
<Main>
  <Container>
  <LoginColumn>
          <Form onSubmit={handleSubmit}>
            <Title>Verify your account</Title>
            <InputWrapper>
              <ContactInput
                ref={otpInputRef}
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                aria-label="OTP"
              />
              <LocalPhoneIcon />
            </InputWrapper>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <SubmitButton type="submit">Verify OTP</SubmitButton>
          </Form>
        </LoginColumn>
    <ImageColumn>
      <GraphicWrapper>
        <ImageContainer>
          <GraphicImg src="/images/login-page-right.png" alt="Decorative graphic" />
          <DotWrapper>
            <Dot />
            <Dot />
            <Dot />
          </DotWrapper>
        </ImageContainer>
      </GraphicWrapper>
    </ImageColumn>
  </Container>
</Main>
<Footer>
        <div style={{ fontWeight: 'bold',marginLeft:'20px' }}>MyWard</div>
        <div>Copyright@2024 IntPurple Technologies LLP.</div>
        <div style={{ marginRight:'20px' }} >Ver:V1.2.1</div>
      </Footer>
  </>
  );
}
const Footer = styled.footer`
  background-color: #0C2340;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 15px;
  width: 100%;
  position: relative;
  bottom: 0;
  text-align: center;
  color: #fff;
`;
const Main = styled.main`
background-color: #fff;
cursor: pointer;
@media (max-width: 991px) {
padding-left: 20px;
}
`;

const Container = styled.div`
display: flex;
cursor: pointer;
@media (max-width: 991px) {
flex-direction: column;
gap: 0;
}
`;

const LoginColumn = styled.section`
display: flex;
flex-direction: column;
cursor: pointer;
width: 50%;
padding: 50px;
@media (max-width: 991px) {
width: 100%;
padding: 10px;
}
`;

const Form = styled.form`
display: flex;
justify-content: center;
flex-direction: column;
flex-grow: 1;
font-size: 16px;
font-weight: 500;
cursor: pointer;
margin: auto 0;
@media (max-width: 991px) {
margin-top: 40px;
}
`;

const Title = styled.h1`
color: #000;
cursor: pointer;
font: 700 30px "Inter", sans-serif;
@media (max-width: 991px) {
text-align: center;
}
`;

const InputWrapper = styled.div`
display: flex;
align-items: center;
gap: 18px;
color: #5d5d5d;
padding: 25px 38px;
margin-top: 20px;
cursor: pointer;
border: 1px solid rgba(93, 93, 93, 1);
border-radius: 12px;
@media (max-width: 991px) {
padding: 20px;
margin-top: 40px;
flex-wrap: wrap;
}
justify-content: space-between;
`;

const ContactInput = styled.input`
font-family: Roboto, sans-serif;
cursor: pointer;
border: none;
outline: none;
flex-grow: 1;
`;

const ImageColumn = styled.aside`
display: flex;
flex-direction: column;
width: 50%;
cursor: pointer;
@media (max-width: 991px) {
display:none;
width: 100%;
margin-top: 40px;
}
`;

const GraphicWrapper = styled.section`
display: flex;
align-items: center;
justify-content: center;
flex-grow: 1;
cursor: pointer;
background-color: #065ad7;
padding: 80px 60px;
@media (max-width: 991px) {
padding: 20px;
}
`;

const ImageContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-top: 149px;
width: 508px;
cursor: pointer;
@media (max-width: 991px) {
margin-top: 40px;
}
`;

const GraphicImg = styled.img`
width: 100%;
cursor: pointer;
`;

const DotWrapper = styled.div`
display: flex;
gap: 10px;
cursor: pointer;
margin-top: 68px;
@media (max-width: 991px) {
margin-top: 40px;
}
`;

const Dot = styled.div`
width: 15px;
cursor: pointer;
height: 15px;
background-color: #fff;
border-radius: 50%;
`;

const SubmitButton = styled.button`
border: 1px solid rgba(93, 93, 93, 1);
border-radius: 12px;
background-color: #1976d2;
color: #fff;
padding: 30px 60px;
font: 500 24px Inter, sans-serif;
margin-top: 48px;
text-align: center;
cursor: pointer;
@media (max-width: 991px) {
margin-top: 40px;
padding: 0 20px;
}


  

 
  @media (max-width: 768px) {
    font-size: 20px;
    padding: 15px 30px;
    margin-top: 30px;
    margin-left:10px;
    margin-right:10px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
    padding: 12px 24px;
    margin-top: 20px;
    margin-left:10px;
    margin-right:10px;
  }
`;


const ErrorMessage = styled.p`
color: red;
margin-top: 20px;
text-align: center;
font-family: "Inter", sans-serif;
`;

export default OTPComponent;